import { Injectable } from '@angular/core';
import { Users } from '../Models/users.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RoleList } from '../Models/role-list.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { CellPropertyModel } from '../../company-management/models/cell-property.model';
import { SystemAttributeEnum } from '../../shared/constant/SystemAttributeEnum.constants';
import { DataListOptionsModel } from '../../company-management/models/data-list-options.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  
  public onHeaderSelect: BehaviorSubject<Array<string>> = new BehaviorSubject([]);
  public recheckValidation: Subject<void> = new Subject();
  public resetPropertyOfDependentCell: Subject<{ rowIndex: number, colKeyCellPropertyMap: Map<string, CellPropertyModel> }> = new Subject();

  constructor(private http:HttpClient) { }

  getAllUsersList(projectId: number){
    return this.http.get(environment.baseUrl + '/login/api/users?projectId='+projectId);
  }

  getAllUsers(projectId: number, pageSize:number, pageNumber:number, advanceSearchArray=null){
    return this.http.post(environment.baseUrl + '/login/api/users/getAll?projectId='+projectId+'&pageSize='+pageSize+'&pageNumber='+pageNumber+'&isFirstAdvanceSearch=false',advanceSearchArray);
  }
 
  getUserRole(projectId: number){
    return this.http.get<RoleList>(environment.baseUrl + '/setting/api/roles?projectId='+projectId+'&activeStatus=1');
  }

  getActiveUserList(projectId: number, activeStatus = 2){
    return this.http.get(environment.baseUrl + '/login/api/users?projectId='+projectId+'&activeStatus='+activeStatus);
  }

  getReportingHeadUserList(projectId: number, userId: Number){
    return this.http.get(environment.baseUrl + '/login/api/users/reportsToOptions?projectId='+projectId+'&userId='+userId);
  }

  saveUserData(userData){
    return this.http.post<any[]>(environment.baseUrl + '/login/api/users',userData)
  }

  getUserDetail(userId, projectId: number){
    return this.http.get<any[]>(environment.baseUrl + '/login/api/users/'+userId+'?projectId='+projectId);
  }

  getOneUser(userId, projectId: number){
    return this.http.get<any[]>(environment.baseUrl + '/login/api/users/getOne/'+userId+'?projectId='+projectId);
  }

  getUserDownHierarchy(projectId: number){
    return this.http.get<any[]>(environment.baseUrl + '/login/api/users/downHierarchy?projectId='+projectId);
  }
  getOwnerAndUsersDownHierarchy(projectId: number){
    return this.http.get<any[]>(environment.baseUrl + '/login/api/users/ownerAndDownHierarchyUsers?projectId='+projectId);
  }

  getMappedDataListOptions(projectId: number, moduleType: number) {
    return this.http.get<DataListOptionsModel[]>(environment.baseUrl + '/transactions/api/customType/getMappedDataListOptions?projectId='+projectId+'&moduleType='+moduleType);
  }
  uploadUser(payload) {
    return this.http.post(environment.baseUrl + '/upload/api/bulk-upload/users/add', payload)
  }

}
