import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { ManageActivitiesModel } from '../../manage-activities/models/manage-activities.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { CellPropertyModel } from '../../company-management/models/cell-property.model';
import { DataListOptionsModel } from '../../company-management/models/data-list-options.model';


@Injectable({
    providedIn: 'root'
})
export class ActivitiesService {
  
    public onHeaderSelect: BehaviorSubject<Array<string>> = new BehaviorSubject([]);
    public recheckValidation: Subject<number> = new Subject();
    public resetPropertyOfDependentCell: Subject<{ rowIndex: number, colKeyCellPropertyMap: Map<string, CellPropertyModel> }> = new Subject();

    constructor(private http: HttpClient) { }

    deleteActivities(activityId: number, moduleType, projectId: number) {
        return this.http.delete<any[]>(
            environment.baseUrl + '/transactions/api/activities/deleteActivityData?&projectId=' + projectId + '&moduleType=' + moduleType + '&activityId=' + activityId);
    }

    getAllActivities(projectId: number) {
        return this.http.get<ManageActivitiesModel[]>(
            environment.baseUrl + '/transactions/api/activities/manageActivities/?projectId=' + projectId);
    }

    getCompanyIdNameList(projectId: number, moduleType: Number, customerView: number = 3) {
        return this.http.get(
            environment.baseUrl + '/transactions/api/company/getCompanyIdName?projectId=' + projectId + '&moduleType=' + moduleType + '&customerView=' + customerView);
    }

    getAllOpportunityList(projectId, companyId, teamFlag: boolean = false, projectFlag: boolean = false) {
        return this.http.get(
            environment.baseUrl + '/transactions/api/opportunity/getOpportunityLookup?projectId=' + projectId + '&companyId=' + companyId + "&teamFlag=" + teamFlag + "&projectFlag=" + projectFlag)
    }

    getContactList(projectId: number) {
        return this.http.get(
            environment.baseUrl + '/transactions/api/contact/getCustomerContacts?projectId=' + projectId);
    }


    uploadActivity(bulkData) {
        return this.http.post(
            environment.baseUrl + '/upload/api/bulk-upload/activities/add', bulkData);
    }

    getMappedDataListOptions(projectId: number, moduleType: number) {
        return this.http.get<DataListOptionsModel[]>(environment.baseUrl + '/transactions/api/customType/getMappedDataListOptions?projectId=' + projectId + '&moduleType=' + moduleType);
    }
}